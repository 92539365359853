import { List, Space, Spin, Typography } from 'antd';

const { Title, Paragraph } = Typography;

type RecordingAndTranscriptProps = {
  isVoiceLoading: boolean;
  sound: string;
  isTranscriptLoading: boolean;
  transcript: string;
};

export const RecordingAndTranscript: React.FC<RecordingAndTranscriptProps> = ({
  isVoiceLoading,
  sound,
  isTranscriptLoading,
  transcript,
}) => {
  const renderTranscript = (text: string) => {
    if (!text) return [];

    const endingString = "user@ [Silence]\nassistant@ Sorry, I didn't hear anything. Can you say that again?";
    if (text.endsWith(endingString)) {
      text = text.slice(0, text.length - endingString.length) + 'user@ [User Hung Up]';
    }

    const scriptMessages = text
      ?.split('\n')
      ?.map((message: string, index: number) => {
        if (message?.startsWith('assistant@')) {
          return { key: index, sender: 'Assistant', message: message.replace('assistant@', '') };
        } else if (message?.startsWith('user@')) {
          return { key: index, sender: 'Client', message: message.replace('user@', '') };
        }
      })
      ?.filter(Boolean);

    return scriptMessages;
  };

  return (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>
      <Spin spinning={isVoiceLoading}>
        <Title level={4} className='mb-6 text-gray-800 border-b pb-2'>
          Recording
        </Title>
        <div className='flex justify-center mb-4'>
          {sound ? (
            <audio controls src={sound} onError={e => console.error('Audio error:', e)}>
              Your browser does not support the audio element.
            </audio>
          ) : (
            <Paragraph className='text-gray-700'>No recording available</Paragraph>
          )}
        </div>
      </Spin>

      <Spin spinning={isTranscriptLoading}>
        <Title level={4} className='mb-6 text-gray-800 border-b pb-2'>
          Transcript
        </Title>
        <Paragraph className='mt-4 text-gray-700'>
          {transcript ? (
            <List
              key={transcript}
              className='call-log-details'
              itemLayout='horizontal'
              dataSource={renderTranscript(transcript)}
              renderItem={item =>
                item && (
                  <List.Item
                    key={item.key}
                    className='call-log-details__item'
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      flexDirection: item.sender == 'Assistant' ? 'row' : 'row-reverse',
                      textAlign: item.sender == 'Assistant' ? 'left' : 'right',
                      padding: '10px',
                    }}
                  >
                    <List.Item.Meta title={<strong>{item.sender}</strong>} description={<span>{item.message}</span>} />
                  </List.Item>
                )
              }
            />
          ) : (
            <div className='flex justify-center mb-4'>
              <Paragraph className='text-gray-700'>No transcript available</Paragraph>
            </div>
          )}
        </Paragraph>
      </Spin>
    </Space>
  );
};
