import { useState } from 'react';
import { Upload, Input, Button, Typography, notification, Space } from 'antd';
import useReseller, { useUploadResellerLogo } from './hooks/brandingHook';
import { fetchUserBranding } from '@/api/novi/brandingApi';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
import { CloseOutlined, EyeInvisibleOutlined, EyeOutlined, PictureOutlined } from '@ant-design/icons';
import LocalStorageUtility from '@/utils/localStorage';
import { useDispatch } from 'react-redux';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { setNoviUser } from '@/store/novi/user/slice';

const { Title, Text } = Typography;

const Branding: React.FC = () => {
  const dispatch = useDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);

  const [businessName, setBusinessName] = useState<string | ''>(noviUser?.campaign_user_name);
  const [twilioAccountId, setTwilioAccountId] = useState<string | ''>(noviUser?.twilio_account_id || '');
  const [twilioAccessToken, setTwilioAccessToken] = useState<string | ''>(noviUser?.twilio_access_token || '');
  const [showTwilioAccountId, setShowTwilioAccountId] = useState<boolean>(false);
  const [showTwilioAccessToken, setShowTwilioAccessToken] = useState<boolean>(false);
  const [logo, setLogo] = useState<string | null>(noviUser?.logo);

  const { handleUpdateUser, loading } = useReseller();
  const { mutate: uploadLogo } = useUploadResellerLogo();

  const handleSave = () => {
    handleUpdateUser({
      emailAddress: noviUser?.email_address,
      campaignUserName: businessName,
      twilioAccountId,
      twilioAccessToken,
    }).then(async () => {
      const data = await fetchUserBranding(noviUser?.email_address);
      console.log(data);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, data.campaignUser);
      dispatch(
        setNoviUser({
          ...data.campaignUser,
        })
      );
    });
  };

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      notification.error({
        message: 'Invalid file type',
        description: 'Please upload an image file',
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        message: 'File is too large',
        description: 'Image must be smaller than 2MB',
      });
      return false;
    }

    const previewURL = URL.createObjectURL(file);
    setLogo(previewURL);

    uploadLogo(file, {
      onSuccess: data => {
        console.log('Uploaded logo URL:', data);
        handleUpdateUser({
          emailAddress: noviUser?.email_address,
          campaignUserName: businessName,
          uploadedLogoS3Url: data,
          twilioAccountId,
          twilioAccessToken,
        }).then(async () => {
          const data = await fetchUserBranding(noviUser?.email_address);
          console.log(data);
          LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, data.campaignUser);
          dispatch(
            setNoviUser({
              ...data.campaignUser,
            })
          );
        });
      },
      onError: error => {
        notification.error({
          message: 'Error uploading logo',
          description: error.message,
        });
      },
    });
    return false;
  };

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Title level={2} className='text-center mb-12 text-4xl font-bold'>
        Brand Your Business
      </Title>

      <div className='flex justify-center'>
        <div className='relative'>
          <Upload showUploadList={false} beforeUpload={beforeUpload} className='cursor-pointer'>
            <div className='w-48 h-48 rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors'>
              {logo ? (
                <div className='relative w-full h-full'>
                  <img src={logo} alt='Business logo' className='w-full h-full object-cover rounded-lg' />
                  <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg'>
                    <Text className='text-white'>Change Logo</Text>
                  </div>
                </div>
              ) : (
                <>
                  <PictureOutlined style={{ fontSize: '40px', color: '#bfbfbf' }} />
                  <Text className='text-gray-600 mt-2'>Click to upload logo</Text>
                </>
              )}
            </div>
          </Upload>
          {logo && (
            <Button
              icon={<CloseOutlined />}
              onClick={e => {
                e.stopPropagation();
                setLogo(null);
              }}
              className='absolute -top-2 -right-2 rounded-full w-8 h-8 p-0 flex items-center justify-center bg-white shadow-md hover:bg-gray-100'
            />
          )}
        </div>
      </div>

      <Text className='text-lg font-semibold'>Business Name</Text>
      <Input
        size='large'
        value={businessName}
        onChange={e => setBusinessName(e.target.value)}
        placeholder='Enter your business name'
      />

      <Title level={3} className='mt-8'>
        Add Your Own Twilio
      </Title>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Text className='text-lg font-semibold'>Twilio Account ID</Text>
        <Input.Password
          size='large'
          value={twilioAccountId}
          onChange={e => setTwilioAccountId(e.target.value)}
          placeholder='Enter your Twilio Account ID'
          iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          visibilityToggle={{
            visible: showTwilioAccountId,
            onVisibleChange: setShowTwilioAccountId,
          }}
        />

        <Text className='text-lg font-semibold mt-4'>Twilio Access Token</Text>
        <Input.Password
          size='large'
          value={twilioAccessToken}
          onChange={e => setTwilioAccessToken(e.target.value)}
          placeholder='Enter your Twilio Access Token'
          iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          visibilityToggle={{
            visible: showTwilioAccessToken,
            onVisibleChange: setShowTwilioAccessToken,
          }}
        />
      </Space>

      <Button
        type='primary'
        size='large'
        onClick={handleSave}
        loading={loading}
        className='w-full h-12 text-lg bg-blue-600 hover:bg-blue-700 mt-4'
      >
        Save Changes
      </Button>
    </Space>
  );
};

export default Branding;
