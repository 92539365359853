import { Button, Form, Input, Typography, Card, notification } from 'antd';
import { IoChevronBack } from 'react-icons/io5';
import { createDataFn } from '@/api/novi/noviLeads';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { useMutation } from '@tanstack/react-query';
import { ManualInsertLeadProps } from '@/pages/Novi/types/Leads';

const { Title } = Typography;

const ManualInsertLead: React.FC<ManualInsertLeadProps> = ({ onClose, goBack, leads }) => {
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const variables = selectedCampaign?.variables ? JSON.parse(selectedCampaign.variables) : [];

  const [form] = Form.useForm();

  const validatePhoneNumber = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('Phone number is required');
    }

    try {
      if (!isValidPhoneNumber(value, 'US')) {
        return Promise.reject('Please enter a valid US phone number');
      }

      const phoneNumber = parsePhoneNumber(value, 'US');
      form.setFieldValue('phone_number', phoneNumber.format('E.164'));

      if (leads.find(lead => lead.phone_number === phoneNumber.format('E.164'))) {
        return Promise.reject('This phone number already exists in the leads list');
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject('Please enter a valid phone number');
    }
  };

  const mutation = useMutation({
    mutationKey: ['createLead'],
    mutationFn: createDataFn,
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Lead created successfully',
      });
      form.resetFields();
      onClose();
    },
  });

  const handleFinish = async (values: any) => {
    console.log('values', values);
    mutation.mutate({ campaign_id: selectedCampaign.number, values });
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff' }}>
      <Button type='link' icon={<IoChevronBack />} onClick={goBack} style={{ marginBottom: '20px' }}>
        Choose another method
      </Button>

      <Card
        title={<Title level={2}>Bulk Insert Phone Numbers</Title>}
        style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}
      >
        <Form form={form} layout='vertical' onFinish={handleFinish}>
          <Form.Item
            name='phone_number'
            label='Phone Number'
            rules={[{ required: true, message: 'Please input the phone number' }, { validator: validatePhoneNumber }]}
          >
            <Input />
          </Form.Item>
          {variables &&
            Object.keys(variables).map(variable => (
              <Form.Item
                key={variable}
                name={variable}
                label={variable.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                rules={[{ required: variables[variable].isMandatory, message: `Please input the ${variable}` }]}
              >
                <Input />
              </Form.Item>
            ))}

          <Button type='primary' htmlType='submit' style={{ width: '100%' }} loading={mutation.isPending}>
            Add a new lead
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default ManualInsertLead;
