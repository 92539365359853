import { useState } from 'react';
import { Row, Col, Card, Typography, Space } from 'antd';
import { FileExcelOutlined, UserAddOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
import UploadCSV from './UploadCSV/UploadCsv';
import { AddNewLeadProps } from '@/pages/Novi/types/Leads';
import ManualInsertLead from './SingleLead/ManualInsertLead';

const { Title, Text } = Typography;

const options = [
  {
    icon: <FileExcelOutlined style={{ fontSize: 24, color: 'white' }} />,
    title: 'Upload CSV',
    description: 'Import leads from a CSV file',
    color: '#52c41a',
    key: 'uploadCSV',
  },
  {
    icon: <UserAddOutlined style={{ fontSize: 24, color: 'white' }} />,
    title: 'Enter Manually',
    description: 'Add lead information manually',
    color: '#1890ff',
    key: 'manualPhones',
  },
];

const AddNewLeads: React.FC<AddNewLeadProps> = ({ leads, closeModal, refetch }) => {
  const [activeComponent, setActiveComponent] = useState('options');
  const campaign_id = useAppSelector(getSelectedCampaignId) as string;

  const handleOnClose = () => {
    setActiveComponent('options');
    refetch();
    closeModal();
  };

  const goBack = () => {
    setActiveComponent('options');
  };

  if (activeComponent === 'uploadCSV') {
    return <UploadCSV onClose={handleOnClose} goBack={goBack} campaignId={campaign_id} />;
  }

  if (activeComponent === 'manualPhones') {
    return <ManualInsertLead onClose={handleOnClose} goBack={goBack} leads={leads} />;
  }

  return (
    <Row gutter={[16, 16]}>
      {options.map(option => (
        <Col xs={24} key={option.key}>
          <Card
            hoverable
            onClick={() => setActiveComponent(option.key)}
            className='h-full transition-all hover:shadow-lg'
            style={{ padding: '24px' }}
          >
            <Space>
              <div
                className='flex items-center justify-center w-12 h-12 rounded-lg'
                style={{ backgroundColor: option.color }}
              >
                {option.icon}
              </div>
              <Space direction='vertical' size={0}>
                <Title level={4} style={{ margin: 0 }}>
                  {option.title}
                </Title>
                <Text type='secondary'>{option.description}</Text>
              </Space>
            </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AddNewLeads;
