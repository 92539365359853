import { Card, Typography, Tabs, Timeline, Tag, Space, Collapse, Empty, Tooltip, Col, Row } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { outboundCallEvent } from '@/pages/Novi/types/Events';
import { useRecording, useTranscript } from '../../../../OutBondAnalytics/hooks/outBondAnalyticsHook';
import { useEffect, useState } from 'react';
import { RecordingAndTranscript } from './RecordingAndTranscript';

const { Title, Text } = Typography;

const getStatusProps = (status: outboundCallEvent['event_state']) => {
  switch (status) {
    case 'SUCCESS':
      return { color: 'green', icon: <CheckCircleOutlined className='text-green-500' /> };
    case 'FAILED':
    case 'FAILED_TERMINAL':
      return { color: 'red', icon: <CloseCircleOutlined className='text-red-500' /> };
    case 'PROCESSING':
      return { color: 'blue', icon: <LoadingOutlined className='text-blue-500' /> };
    default:
      return { color: 'default', icon: null };
  }
};

const StatItem = ({
  title,
  value,
  icon,
  tooltip,
}: {
  title: string;
  value: string | number;
  icon?: JSX.Element;
  tooltip?: string | JSX.Element;
}) => (
  <div className='flex flex-col'>
    <Text type='secondary' className='text-sm mb-1'>
      {title}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      )}
    </Text>
    <div className='flex items-center'>
      {icon && <span className='mr-2'>{icon}</span>}
      <Text strong className='text-base'>
        {value}
      </Text>
    </div>
  </div>
);

const ScheduledCallsDashboard = ({ scheduledEvent }: { scheduledEvent: outboundCallEvent }) => {
  const [transcript, setTranscript] = useState<string>('');
  const [sound, setSound] = useState<string>('');
  const { mutate: fetchTranscript, data: transcriptData, isPending: isTranscriptLoading } = useTranscript();
  const { mutate: fetchRecording, data: voiceData, isPending: isVoiceLoading } = useRecording();

  useEffect(() => {
    if (scheduledEvent) {
      fetchRecording(scheduledEvent.callSid as string);
      fetchTranscript({ callSid: scheduledEvent.callSid as string, forwardedFrom: scheduledEvent.username as string });
    }
  }, [scheduledEvent]);

  useEffect(() => {
    if (transcriptData) {
      setTranscript(transcriptData.transcript);
    }
    if (voiceData) {
      setSound(voiceData.url);
    }
  }, [transcriptData, voiceData]);

  const renderStatsCard = () => (
    <Card size='small' className='mb-4'>
      <Row gutter={16} align='middle'>
        {[
          {
            title: 'Current State',
            value: scheduledEvent?.event_state || 'N/A',
            icon: getStatusProps(scheduledEvent?.event_state).icon,
            tooltip: (
              <>
                1. PENDING: Scheduled, not processed
                <br />
                2. FAILED: Failed, has retries left
                <br />
                3. SUCCESS: Processed successfully
                <br />
                4. FAILED_TERMINAL: Failed, no more retries
                <br />
                5. PROCESSING: Currently being processed
              </>
            ),
          },
          {
            title: 'Number of Tries',
            value: scheduledEvent?.num_tries_so_far || '0',
            icon: <SyncOutlined className='text-blue-500' />,
            tooltip: 'Number of times the event has been tried',
          },
          {
            title: 'Next Execution',
            value: scheduledEvent?.event_execution_time
              ? new Date(scheduledEvent.event_execution_time).toLocaleString()
              : 'N/A',
            icon: <ClockCircleOutlined className='text-gray-500' />,
            tooltip: 'Upcoming execution time of next trigger',
          },
        ].map((stat, index) => (
          <Col span={8} key={index}>
            {/* @ts-ignore */}
            <StatItem {...stat} />
          </Col>
        ))}
      </Row>
    </Card>
  );

  const renderHistory = () => (
    <Card title='State History' className='mb-4'>
      {scheduledEvent?.state_histories?.length ? (
        <Timeline>
          {JSON.parse(scheduledEvent.state_histories).map((state: string, index: number) => {
            const { color, icon } = getStatusProps(state);
            return (
              <Timeline.Item key={index} color={color} dot={icon}>
                <Tag color={color}>{state}</Tag>
                <Text type='secondary'>
                  {scheduledEvent.execution_times ? (
                    <div className='text-xs text-gray-500'>
                      {new Date(JSON.parse(scheduledEvent.execution_times)[index]).toLocaleString()}
                    </div>
                  ) : (
                    'N/A'
                  )}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ) : (
        <Empty description='No history available' />
      )}
    </Card>
  );

  const formatVariables = (variables: outboundCallEvent['variables']) => {
    try {
      const parsed = typeof variables === 'string' ? JSON.parse(variables) : variables;
      return (
        <Collapse
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Variables',
              children: Object.entries(parsed).map(([key, value]) => (
                <div key={key} style={{ marginBottom: 8 }}>
                  <Text strong>{key}: </Text>
                  <Text>{JSON.stringify(value, null, 2)}</Text>
                </div>
              )),
            },
          ]}
        />
      );
    } catch {
      return <Tag color='error'>Invalid variables format</Tag>;
    }
  };

  return (
    <div style={{ padding: 24 }}>
      <Space direction='vertical' style={{ width: '100%' }} size='large'>
        <Space align='center' style={{ justifyContent: 'space-between', width: '100%' }}>
          <Title level={4}>
            <Space>
              <PhoneOutlined />
              Scheduled Call Details
            </Space>
          </Title>
          {scheduledEvent?.callSid && <Tag color='blue'>Call ID: {scheduledEvent.callSid.slice(-6)}</Tag>}
        </Space>
        {renderStatsCard()}
        <Tabs
          defaultActiveKey='details'
          items={[
            {
              key: 'details',
              label: 'Details',
              children: formatVariables(scheduledEvent?.variables),
            },
            {
              key: 'history',
              label: 'History',
              children: renderHistory(),
            },
            {
              key: 'recording',
              label: 'Recording',
              children: (
                <RecordingAndTranscript
                  isVoiceLoading={isVoiceLoading}
                  sound={sound}
                  isTranscriptLoading={isTranscriptLoading}
                  transcript={transcript}
                />
              ),
            },
          ]}
        />
      </Space>
    </div>
  );
};

export default ScheduledCallsDashboard;
