import { OverViewCardInfo } from '../OverviewCardInfo';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { GreetingPhraseForm } from './GreetingPhraseForm';
import { MODAL_IDS } from 'src/enums/EModal';
import { User } from '@/types/TAccounts';
import { useEffect, useState } from 'react';
import { languageExamples } from 'src/constants/languageGreetings';
import DOMPurify from 'dompurify';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

export interface LanguageGreet {
  language: string;
  greeting: string;
}

export const GreetingPhraseInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  isNoviUser?: boolean;
}> = ({ data, refetch, isNoviUser }) => {
  const systemPhrase = data?.system_phrase || '';

  const [languages, setLanguages] = useState<LanguageGreet[]>([]);

  useEffect(() => {
    if (data?.selected_languages) {
      const lan: string[] = JSON.parse(data?.selected_languages || '[]');
      const lanWithoutEng = lan.filter(item => item !== 'English');
      const lg =
        data?.language_greetings && data.language_greetings !== 'na' ? JSON.parse(data.language_greetings) : null;

      const lanWithoutEngGreet: LanguageGreet[] =
        lg &&
        lanWithoutEng.map((item: string) => {
          return {
            language: item,
            greeting: lg[item] || languageExamples[item as keyof typeof languageExamples],
          };
        });
      setLanguages(lanWithoutEngGreet);
    }
  }, [data?.selected_languages]);

  const sanitizeAndFormatText = (text: string) => {
    const sanitized = DOMPurify.sanitize(text);
    return sanitized.replace(/\n/g, '<br />');
  };

  const InfoComponent = () => {
    return (
      <div className='flex flex-col gap-4'>
        {systemPhrase && (
          <div className='flex flex-col'>
            <div className='flex items-center gap-1'>
              <span className='font-medium'>English</span>
              <CopyableText text={systemPhrase} hideText />
            </div>
            <span
              className='pl-4 w-9/12 mt-1 pt-2 pb-4'
              dangerouslySetInnerHTML={{ __html: sanitizeAndFormatText(systemPhrase) }}
            ></span>
          </div>
        )}
        {languages.length > 0 &&
          languages.map(item => {
            return (
              <div key={item.language} className='flex flex-col'>
                <div className='flex items-center gap-1'>
                  <span className='font-medium'>{item.language}</span>
                  <CopyableText text={item.greeting} hideText />
                </div>
                <span
                  className='pl-4 w-9/12 mt-1 pt-2 pb-4'
                  dangerouslySetInnerHTML={{ __html: sanitizeAndFormatText(item.greeting) }}
                ></span>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <OverViewCardInfo
      info={<InfoComponent />}
      formActionType='Edit'
      form={
        <GreetingPhraseForm
          system_phrase={systemPhrase}
          refetch={refetch}
          languageGreets={languages}
          isNoviUser={isNoviUser}
        />
      }
      width={'80%'}
      footer={null}
      modalId={MODAL_IDS.GREETING_FORM_MODAL}
    />
  );
};
