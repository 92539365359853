import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Tag,
  Space,
  Table,
  Tooltip,
  Input,
  Typography,
  Layout,
  FormInstance,
  notification,
  Checkbox,
} from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser } from '@/store/novi/user/selector';
//@ts-ignore
import RegisterPageV3 from '../../../../../v1/RegisterPageV3.jsx';
import { Content, Header } from 'antd/es/layout/layout';
import { useDispatch } from 'react-redux';
import { setNoviSelectedCampaign, setNoviSelectedCampaignId, setNoviUser } from '@/store/novi/user/slice';
import { fetchToken, saveTermsAcceptance } from '@/api/user';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { useCampaignClient } from './hooks/useCampaignClient';
import { CustomModal } from '@/components/CustomModal/CustomModal.js';
import { GetStandAloneNumberForm } from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetStandAloneNumberForm.js';
import { PhoneOutlined } from '@mui/icons-material';
import { provisionNumberRequest } from '@/api/reservedNumbers.js';
import GetChooseNumberForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetChooseNumberForm.js';
import { useMutation } from '@tanstack/react-query';
import GetBundleApproveForm from '@/pages/ReceptionistDashboard/TestItOut/Steps/GetBundleApproveForm.js';
import { fetchUserBranding } from '@/api/novi/brandingApi.js';
import { APP_LANDING_PAGE } from 'src/enums/ERoutes.js';

const { Title } = Typography;

const NoviCampaigns: React.FC = () => {
  const dispatch = useDispatch();
  const noviUser = useAppSelector(getLoggedInNoviUser);
  const formRef = useRef<FormInstance>(null);

  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false);
  const [isBYOTInstructionsModalVisible, setIsBYOTInstructionsModalVisible] = useState<boolean>(false);
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false);
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false);
  const [twilioDocsRequirements] = useState<any>([]);
  const [currentClient, setCurrentClient] = useState('');

  const { data: clientsFetched, isLoading } = useCampaignClient(noviUser.email_address);
  const [clients, setClients] = useState(clientsFetched);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: (response: any) => {
      const data = response;
      if (data.status && data.status === 'success' && data.provisionedNumber) {
        setIsBookNumberModalVisible(false);
        setClients(prevState => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            items: prevState.items.map(item => {
              if (item.number === currentClient) {
                return {
                  ...item,
                  reservedNumber: data.provisionedNumber,
                };
              }
              return item;
            }),
          };
        });
        notification.success({ message: 'Provisioned a number successfully' });
      }
    },
    onError: error => {
      let errorMsg;
      if (error?.message.includes('Authenticate')) errorMsg = 'Invalid Twilio credentials';
      notification.error({ message: errorMsg || 'Failed to provision number' });
    },
  });

  const { mutate: saveTermsAcceptanceMutation, isPending: isSavingTermsAcceptance } = useMutation({
    mutationKey: ['saveTermsAcceptance'],
    mutationFn: () => saveTermsAcceptance(noviUser.email_address),
    onSuccess: async () => {
      notification.success({ message: 'Terms acceptance saved successfully' });
      setIsTermsModalVisible(false);
      const data = await fetchUserBranding(noviUser?.email_address);
      console.log(data);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, data.campaignUser);
      dispatch(
        setNoviUser({
          ...data.campaignUser,
        })
      );
    },
    onError: () => {
      notification.error({ message: 'Failed to save terms acceptance' });
    },
  });

  const getToken = async (campaign_id: string) => {
    try {
      const response = await fetchToken(campaign_id);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN, response.token);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const handleOpenCampaign = async (number: string) => {
    await getToken(number);
    dispatch(setNoviSelectedCampaignId(number));
    dispatch(setNoviSelectedCampaign(clients?.items.find(item => item.number === number)));
  };

  const columns = [
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      key: 'business_name',
      render: (text: string) => (
        <Tooltip title={text}>
          <span>{text.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Business Type',
      dataIndex: 'businessType',
      key: 'businessType',
      render: (tag: string) => (tag ? <Tag color='blue'>{tag}</Tag> : <Tag>No Business Type</Tag>),
    },
    {
      title: 'Reserved Number',
      dataIndex: 'reservedNumber',
      key: 'reservedNumber',
      render: (text: string, ...args: any) => {
        return text?.length ? (
          text
        ) : (
          <Button
            type='primary'
            onClick={() => {
              if (noviUser.twilio_account_id && noviUser.twilio_access_token) {
                setCurrentClient(args[0].number);
                openStandAloneNumberModal();
              } else {
                openBYOTInstructionsModal();
              }
            }}
            icon={<PhoneOutlined />}
            loading={isProvisionApiPending}
            disabled={isProvisionApiPending}
          >
            Get your standalone number
          </Button>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <Space size='middle'>
          <Tooltip title='Open'>
            <Button type='primary' onClick={() => handleOpenCampaign(record.number)}>
              Open
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const openBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(true);
  };

  const closeBYOTInstructionsModal = () => {
    setIsBYOTInstructionsModalVisible(false);
  };

  const openStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(true);
  };

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false);
  };

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true);
  };

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false);
  };

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country);
    closeStandAloneNumberModal();
    openBookNumberModal();
  };

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
    dispatch(setNoviSelectedCampaign(null));
    setShowCreateCampaign(false);
  };

  const handleCreateCampaign = () => {
    setShowCreateCampaign(true);
  };

  const bookNumberRequest = async () => {
    if (!selectedCountry) {
      return;
    }

    var countryCode = selectedCountry.split(' - ')[2]; // Something like "US"

    let payload = {
      username: currentClient,
      countryCode,
      isNovi: true,
    };

    provisionNumberRequestMutation(payload);
    setIsBookNumberModalVisible(false);
    closeStandAloneNumberModal();
  };

  const filteredData = clients?.items.filter(item =>
    item.business_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleTermsSubmit = () => {
    if (termsAccepted) {
      saveTermsAcceptanceMutation();
    } else {
      notification.error({
        message: 'Terms and Conditions',
        description: 'You must accept the Terms and Conditions to continue.',
      });
    }
  };

  useEffect(() => {
    if (!noviUser.termsAccepted) setIsTermsModalVisible(true);
    setClients(clientsFetched);
  }, [clientsFetched]);

  if (showCreateCampaign) {
    return (
      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack} style={{ marginRight: 16 }}>
            Back to Campaigns
          </Button>
        </Header>
        <Content style={{ padding: '24px' }}>
          <RegisterPageV3
            isCampaign
            debrand
            campaign_user_email={noviUser.email_address}
            campaign_user_password={noviUser.password}
            purchase_quotes={noviUser.purchase_quotes}
            campaign_user_name={noviUser.campaign_user_name}
          />
        </Content>
      </Layout>
    );
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <CustomModal
        title='Bring Your Own Twilio'
        isModalOpen={isBYOTInstructionsModalVisible}
        confirmAction={closeBYOTInstructionsModal}
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button
        className=''
      >
        <div className='text-center'>
          <p>
            Please head over to the <span className='font-bold'>"Branding"</span> tab to add your twilio config
          </p>
          <p className='font-bold'>OR</p>
          <p>
            Follow this{' '}
            <a
              className='text-blue-600'
              href='https://www.loom.com/share/ae3b7257107243e39bc1792b5e2c83c0'
              target='_blank'
              rel='noreferrer'
            >
              video
            </a>
          </p>
        </div>
      </CustomModal>
      <CustomModal
        title='Terms and Conditions'
        isModalOpen={isTermsModalVisible}
        confirmAction={handleTermsSubmit}
        cancelAction={() => {}} // Empty function to prevent closing on cancel
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button
        closable={false} // Prevent closing by clicking outside or pressing ESC
        maskClosable={false}
        okButtonProps={{ loading: isSavingTermsAcceptance }}
      >
        <p>Please read and accept our Terms and Conditions before creating a campaign.</p>
        <Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}>
          I have read and agree to the{' '}
          <a
            href={`${APP_LANDING_PAGE}/terms-of-service`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-600 hover:text-black'
          >
            Terms and Conditions
          </a>
        </Checkbox>
      </CustomModal>
      <Title level={2}>My Campaigns</Title>
      <div className='flex flex-wrap justify-between items-center mb-4'>
        <Input.Search
          className='w-72 mb-2 sm:mb-0'
          placeholder='Search campaigns'
          onSearch={handleSearch}
          onChange={e => handleSearch(e.target.value)}
          allowClear
        />
        <Button type='primary' icon={<PlusOutlined />} onClick={handleCreateCampaign} className='mb-2 sm:mb-0'>
          Create Campaign
        </Button>
      </div>
      <Table columns={columns} dataSource={filteredData} loading={isLoading} />
      <CustomModal
        title='Get your standalone number'
        isModalOpen={isStandAloneNumberModalVisible}
        confirmAction={handleConfirmAction}
        cancelAction={closeStandAloneNumberModal}
        okButtonProps={{ loading: isProvisionApiPending }}
        okText='Get Random Number'
      >
        <GetStandAloneNumberForm
          debrand
          isNovi
          ref={formRef}
          setCountry={(country: string) => setSelectedCountry(country)}
          onSubmit={handleFormSubmit}
          setChooseNumberModalOpen={setChooseNumberModalOpen}
        />
      </CustomModal>
      <CustomModal
        title='Save your changes'
        isModalOpen={isBookNumberModalVisible}
        confirmAction={() => bookNumberRequest()}
        cancelAction={closeBookNumberModal}
      >
        <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
      </CustomModal>
      <CustomModal
        title='Get Your Favourite Area Code'
        isModalOpen={ChooseNumberModalOpen}
        confirmAction={() => {
          setChooseNumberModalOpen(false);
          setIsBookNumberModalVisible(false);
        }}
        cancelAction={() => setChooseNumberModalOpen(false)}
        footer={null}
      >
        <GetChooseNumberForm
          isOpen={ChooseNumberModalOpen}
          onClose={() => setChooseNumberModalOpen(false)}
          selectedCountryCode={selectedCountry.split(' - ')[2]}
          bookNumberRequest={() => bookNumberRequest()}
        />
      </CustomModal>
      <CustomModal
        title={null}
        isModalOpen={isNonUSDetailsModalOpen}
        confirmAction={() => setIsNonUSDetailsModalOpen(false)}
        cancelAction={() => setIsNonUSDetailsModalOpen(false)}
        width={700}
        footer={null}
      >
        <GetBundleApproveForm
          countryCodeNumber={selectedCountry.split(' - ')[2]}
          twilioDocsRequirements={twilioDocsRequirements.requirements}
          regulationSid={twilioDocsRequirements.regulationSid}
          setClientDetails={() => {}}
          onClose={() => setIsNonUSDetailsModalOpen(false)}
        />
      </CustomModal>
    </Space>
  );
};

export default NoviCampaigns;
