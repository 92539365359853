// CommonSider.tsx
import React, { useEffect, useState } from 'react';
import { Button, Card, Menu, MenuProps, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import './Sider.scss';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import OnboardingStepsList from '@/pages/ReceptionistDashboard/TestItOut/v2/OnboardingStepsList';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getReceptionistReseller } from '@/api/unbrandedReceptionist';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '@/store/account/slice';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import gift from 'src/assets/images/gift.png';
import OfferModal from './OfferModal';
import { getResellerData } from '@/store/reseller/selector';

interface CommonSiderProps {
  items: MenuProps['items'];
  logo: React.ReactNode;
  activeMenuItem: ResellerSiderItem | string;
  className?: string;
  menuRef?: React.RefObject<HTMLDivElement>;
  handleClick: MenuProps['onClick'];
  setOpenedOnboarding?: any;
  openedOnboarding?: boolean;
  tourOpen?: boolean;
  debrand?: boolean;
  openKeys?: string[];
  onOpenChange?: (openKeys: string[]) => void;
  resellerSider?: boolean;
}

const CommonSider: React.FC<CommonSiderProps> = ({
  items,
  logo,
  activeMenuItem,
  className = '',
  menuRef,
  handleClick,
  openKeys,
  onOpenChange,
  setOpenedOnboarding,
  openedOnboarding,
  tourOpen,
  debrand = false,
  resellerSider,
}) => {
  const baseClass = 'common-sider';
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentReseller = useAppSelector(getResellerData);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { data } = useQuery({
    queryKey: ['getResellerInfoByReceptionist'],
    queryFn: () => getReceptionistReseller({ resellerEmail: loggedInUser.reseller_email || '' }),
    enabled: !!(debrand && loggedInUser.reseller_email),
  });

  // Generate dynamic styles for the active menu item
  const getMenuItemStyles = () => {
    if (data?.reseller.theme_color) {
      return `
        .${baseClass} .ant-menu-item-selected {
          background-color: ${data?.reseller.theme_color}15 !important;
        }
        .${baseClass} .ant-menu-item-selected span {
          color: ${data?.reseller.theme_color} !important;
        }
        .${baseClass} .ant-menu-item::after {
          border-right-color: ${data?.reseller.theme_color} !important;
        }
      `;
    }
    return '';
  };
  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number);
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
  }, []);

  const handleReservedNumberClick = () => {
    if (provisionedNumber || (!provisionedNumber && !loggedInUser.demo)) {
      dispatch(setCurrentPage(ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS));
      return;
    }
    if (!provisionedNumber && loggedInUser.demo) {
      setOpenedOnboarding(true);
    }
  };

  return (
    <>
      {data?.reseller.theme_color && <style>{getMenuItemStyles()}</style>}
      <Sider
        collapsed={false}
        className={`${baseClass} ${className}`}
        collapsible={false}
        theme='light'
        breakpoint='md'
      >
        <div>{logo}</div>
        {!resellerSider && (
          <div className='flex items-center justify-center'>
            <div
              onClick={handleReservedNumberClick}
              className={`inline-flex mx-auto border cursor-pointer bg-gray-50 shadow-lg mb-6 rounded-lg items-center justify-center p-4 font-bold text-button-success ${loggedInUser.demo ? 'border-red-600' : ''}`}
            >
              <Tooltip
                title={
                  provisionedNumber
                    ? "Your AI's reserved phone number. Click for more info."
                    : 'Click here to reserve a phone number for your AI.'
                }
                className={`sm:${provisionedNumber ? 'text-lg' : 'text-sm'} text-sm ${loggedInUser.demo ? 'text-red-700' : ''}`}
              >
                📞 {provisionedNumber ? provisionedNumber : 'No reserved number'}
              </Tooltip>
            </div>
          </div>
        )}
        <div ref={menuRef}>
          <Menu
            items={items}
            selectedKeys={[activeMenuItem]}
            onClick={handleClick}
            mode='inline'
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          />
        </div>
        {!loggedInUser.resellerClient && !currentReseller.email_address && (
          <>
            <Card className='offer-card mt-3'>
              <h3 className='text-center text-white offer-heading'>Congratulations!</h3>
              <img src={gift} className='offer-image' />
              <h4 className='text-center text-white text-xs'>You have been choosen!</h4>
              <h3 className='text-center text-white offer-heading'>Earn 2 free months!</h3>
              <Button onClick={showModal} className='m-auto block'>
                Claim Now
              </Button>
            </Card>

            <OfferModal isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} />
          </>
        )}
        {!openedOnboarding && !tourOpen && !debrand && (
          <div className='mt-8'>
            <OnboardingStepsList setOpenedOnboarding={setOpenedOnboarding} />
          </div>
        )}
      </Sider>
    </>
  );
};

export default CommonSider;
