import React, { useState } from 'react';
import { Form, Modal } from 'antd';
import CreatableSelect from 'react-select/creatable';
import { MultiValue } from 'react-select';
import { OverViewCardForm } from '../../Cards/OverviewCardForm';
import { isValidEmail } from '@/utils/helper';

interface Props {
  open: boolean;
  setOpen: (val: boolean | null) => void;
  emails: string[];
  saveEmails: (val: string[]) => void;
}

export interface OptionType {
  label: string;
  value: string;
}

const AddEmails: React.FC<Props> = ({ open, setOpen, emails, saveEmails }) => {
  const [form] = Form.useForm();
  const [someInvalid, setSomeInvalid] = useState(false);

  const handleSubmit = async (values: { emails: MultiValue<OptionType> }) => {
    const abc = values.emails.some(email => !isValidEmail(email.value));
    if (abc) {
      setSomeInvalid(abc);
      return;
    } else {
      // save to db
      setOpen(false);
      saveEmails(values.emails.map(item => item.value));
    }
  };

  const FormItems = () => (
    <>
      <Form.Item name='emails' rules={[{ required: true, message: 'Please add at least one email' }]}>
        <CreatableSelect
          isClearable
          isMulti
          placeholder='a@b.com, c@d.com'
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              width: '100%',
              boxShadow: 'none',
              borderWidth: '1px',
              borderRadius: '0',
            }),
            indicatorsContainer: baseStyles => ({
              ...baseStyles,
              display: 'none',
            }),
          }}
        />
      </Form.Item>
      {someInvalid && <span className='text-red-500'>Some of the emails are invalid</span>}
    </>
  );

  return (
    <Modal
      title='Add Email Recipients'
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      width={600}
      footer={null}
    >
      <div className='p-4'>
        <OverViewCardForm
          form={form}
          initialValues={{
            emails: emails.map((item: string) => ({
              label: item,
              value: item,
            })),
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default AddEmails;
