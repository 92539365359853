import React, { useState } from 'react';
import { Card, Typography, Button, message, Modal, InputNumber } from 'antd';
import { DollarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { getBillingOverview, stripeCancelOrUpdate, createCheckoutSessionNewResellers } from '@/api/reseller';
import { useMutation, useQuery } from '@tanstack/react-query';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { Loading } from '@/components/Loading';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateReseller } from '@/store/reseller/slice';

const { Title, Text } = Typography;

interface BillingOverview {
  data: {
    basePlanStatus: string;
    voiceUsageCharge: number;
    subAccounts: SubAccount[];
  };
}

interface SubAccount {
  username: string;
  freeMinutesLeft: number;
  totalFreeMinutes: number;
  totalMinutesUsed: number;
}

const ResellerSubscription: React.FC = () => {
  const dispatch = useAppDispatch();

  const resellerData = useAppSelector(getResellerData);
  const [purchaseSeatsModal, setPurchaseSeatsModal] = useState<boolean>(false);
  const [seatsToBuy, setSeatsToBuy] = useState<number | null>(5);

  const { data: billingOverview, isPending: isLoadingBillingOverview } = useQuery<BillingOverview>({
    queryKey: ['billingOverview', resellerData?.email_address],
    queryFn: () => getBillingOverview({ email_address: resellerData?.email_address || '' }),
    enabled: !!resellerData?.email_address,
  });

  const stripeSubscriptionUpdateMutation = useMutation({
    mutationFn: stripeCancelOrUpdate,
    onSuccess: data => {
      if (data.status === 'success') {
        window.location.href = data.redirectPaymentURL;
      } else {
        message.error('Failed to cancel subscription');
      }
    },
    onError: () => {
      message.error('An error occurred while cancelling the subscription');
    },
  });

  const createCheckoutSessionNewResellersMutation = useMutation({
    mutationFn: createCheckoutSessionNewResellers,
    onSuccess: (data: any) => {
      setPurchaseSeatsModal(false);
      if (data?.redirectPaymentURL) {
        message.success('Redirecting to payment page');

        window.location.href = data?.redirectPaymentURL;

        // @ts-ignore
        window.gtag('event', 'resellerPurchaseClicked', {
          event_category: 'EditDemo.js',
          event_label: 'success',
        });
      } else if (data.quantity) {
        message.success(`Seats Added to your subscription ${data.quantity}`);
        dispatch(
          updateReseller({
            ...resellerData,
            totalReceptionists: resellerData.totalReceptionists + data.quantity,
            unreservedReceptionists: resellerData.unreservedReceptionists + data.quantity,
          })
        );
      }
    },
    onError: () => {
      message.error('Error creating checkout session');
    },
  });

  const handleUpdatePaymentMethodConfirm = () => {
    stripeSubscriptionUpdateMutation.mutate({
      username: resellerData.email_address,
      action: 'payment_method_update',
      reseller: true,
    });
  };

  const handlePurchaseSeats = () => {
    if (seatsToBuy) {
      createCheckoutSessionNewResellersMutation.mutate({
        resellerEmail: resellerData.email_address,
        quantity: seatsToBuy,
      });
    }
  };

  if (isLoadingBillingOverview) return <Loading />;

  const isSubscriptionNA = billingOverview?.data?.basePlanStatus === 'N/A';

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.SUBSCRIPTION}>
      <div className='h-full p-6'>
        <Title level={2}>Subscription Management</Title>
        <Text>Manage your subscription below. Cancel or update your payment information as needed.</Text>

        <Card title='Subscription Overview' className='m-3'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text strong>Base Plan Status: </Text>
              <Text type={billingOverview?.data?.basePlanStatus === 'active' ? 'success' : 'danger'}>
                {billingOverview?.data?.basePlanStatus}
              </Text>
            </div>
            <div>
              <Text strong>Voice Usage Charge: </Text>
              <Text type='danger'>${billingOverview?.data?.voiceUsageCharge || 0}</Text>
            </div>
          </div>
        </Card>

        <Card title='Actions' style={{ marginTop: 24 }}>
          {isSubscriptionNA ? (
            <Button icon={<ShoppingCartOutlined />} onClick={() => setPurchaseSeatsModal(true)} type='primary'>
              Purchase Subscription
            </Button>
          ) : (
            <>
              <Button
                icon={<DollarOutlined />}
                onClick={handleUpdatePaymentMethodConfirm}
                loading={stripeSubscriptionUpdateMutation.isPending}
                type='primary'
              >
                Update Payment Method
              </Button>
            </>
          )}
        </Card>

        {!isSubscriptionNA && (
          <Card title='Sub Accounts' style={{ marginTop: 24 }}>
            {billingOverview?.data?.subAccounts.map((account: SubAccount, index: number) => (
              <Card key={index} type='inner' title={account.username} style={{ marginTop: 16 }}>
                <Text>
                  Free Minutes Left: {account.freeMinutesLeft} / {account.totalFreeMinutes}
                </Text>
                <br />
                <Text>Total Minutes Used: {account.totalMinutesUsed}</Text>
              </Card>
            ))}
          </Card>
        )}

        <Modal
          title='Purchase Subscription'
          open={purchaseSeatsModal}
          onOk={handlePurchaseSeats}
          onCancel={() => setPurchaseSeatsModal(false)}
          okText='Purchase'
        >
          <Title level={4}>Buy seats for your reseller dashboard</Title>
          <Text>How many seats would you like to purchase?</Text>
          <div style={{ marginTop: 16 }}>
            <InputNumber
              min={5}
              value={seatsToBuy}
              onChange={value => setSeatsToBuy(value)}
              style={{ width: '100%' }}
            />
          </div>
        </Modal>
      </div>
    </ResellerDashboard>
  );
};

export default ResellerSubscription;
